/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginMethods, UserClientSpecificCustomData, UserProfile } from '../../models/AuthModels';
import Button, { ButtonSize, ButtonType } from '../shared/form-control/Button';
import DropdownSelect from '../shared/form-control/DropdownSelect';
import { SupportedLanguage, supportedLanguages } from '../../types/Languages';
import AuthService from '../../services/AuthService';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import StorageService from '../../services/StorageService';
import CurrentUserService from '../../services/CurrentUserService';
import i18next from 'i18next';
import { useCurrentUser } from '../../global-state/Auth';
import { useShallow } from 'zustand/react/shallow';
import { useCurrentClient } from '../../global-state/Clients';
import { defaultMemberFields, MemberField, MemberFieldKey } from '../../models/ClientMemberFields';
import { FieldRenderer } from './FieldRenderer';
import { getHighestRole } from '../../models/Role';
import User from '../../models/User';
import useBusy from '../../hooks/useBusy';
import ProfileAvatar, { ImageSize } from '../shared/profile-image/ProfileAvatar';
import { useQueryClient } from '@tanstack/react-query';
import DateUtils from '../../utils/DateUtils';

const Profile: FC = () => {
  const { t } = useTranslation(['user-settings', 'common']);
  const queryClient = useQueryClient();

  const currentClient = useCurrentClient((x) => x.value);
  const memberFields = useMemo(() => {
    const fields = currentClient?.memberFields?.length
      ? currentClient.memberFields
      : defaultMemberFields.filter((field) => field.isRequired && field.isEditable);

    return fields.filter((field) => field.key !== MemberFieldKey.Role);
  }, [currentClient]);

  const [currentUser, setCurrentUser] = useCurrentUser(useShallow((x) => [x.value, x.setValue]));
  const toasts = useToasts();

  const [formState, setFormState] = useState<UserProfile>({} as UserProfile);
  const [hasChanges, setHasChanges] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { isBusy, withBusy } = useBusy();

  const languageOptions = useMemo(() => {
    return supportedLanguages.map((lang) => ({
      id: lang.id,
      value: lang.value,
      text: t(`common:languages.${lang.id}`),
    }));
  }, [t]);

  useEffect(() => {
    const newFormState: UserProfile = {
      email: currentUser?.email || '',
      firstName: currentUser?.firstName || '',
      lastName: currentUser?.lastName || '',
      languageCode: currentUser?.language || 'en',
      phoneNumber: currentUser?.phoneNumber || '',
      birthday: currentUser?.birthday || undefined,
      address: currentUser?.address || undefined,
      userImageId: currentUser?.userImageId || undefined,

      clientCustomData: {
        ...(currentUser?.clientCustomData || {}),
      },
    };

    if (currentClient) {
      newFormState.clientCustomData![currentClient.id] = {
        departmentId: currentUser?.clientCustomData?.[currentClient!.id]?.departmentId || undefined,
        employeeId: currentUser?.clientCustomData?.[currentClient!.id]?.employeeId || '',
        startDate: currentUser?.clientCustomData?.[currentClient!.id]?.startDate || undefined,
        groupId: currentUser?.clientCustomData?.[currentClient!.id]?.groupId || undefined,
        positionId: currentUser?.clientCustomData?.[currentClient!.id]?.positionId || undefined,
        officeLocation: currentUser?.clientCustomData?.[currentClient!.id]?.officeLocation || undefined,
      };
    }

    setFormState(newFormState);
  }, [currentClient, currentUser]);

  useEffect(() => {
    const evalKeys = (obj: any, path: string[]): any => {
      return (
        path.reduce((acc, key) => {
          return acc?.[key];
        }, obj) || undefined
      );
    };

    const detectChanges = (obj: any, path: string[]): boolean => {
      const targetObj = evalKeys(obj, path);
      const sourceObj = evalKeys(currentUser, path.slice(-1)[0] === 'languageCode' ? [...path.slice(0, -1), 'language'] : path);

      if (DateUtils.isValidDate(targetObj) || DateUtils.isValidDate(sourceObj)) {
        return targetObj !== sourceObj;
      }

      if (typeof targetObj === 'object') {
        return Object.keys(targetObj).some((key) => detectChanges(obj, [...path, key as string]));
      }

      return targetObj !== sourceObj;
    };

    const changesDetected = Object.keys(formState).some((key) => {
      return detectChanges(formState, [key]);
    });

    setHasChanges(changesDetected);
  }, [formState, currentUser]);

  const handleFieldChange = (key: string, value: any, _isRequired: boolean, fieldInfo: MemberField | null) => {
    if (fieldInfo?.isUserField || key === 'userImageId' || key === 'languageCode') {
      setFormState((prev) => ({ ...prev, [key]: value }));
    } else {
      setFormState((prev) => ({
        ...prev,
        clientCustomData: {
          ...(prev.clientCustomData ?? {}),
          [currentClient!.id]: {
            ...(prev.clientCustomData?.[currentClient!.id] ?? {}),
            [key]: value,
          },
        },
      }));
    }
    setErrors((prev) => ({ ...prev, [key]: '' }));
  };

  const validateField = useCallback(
    (key: string, value: any, isRequired: boolean): string | null => {
      if (isRequired && !value) {
        return t('common:validation.required');
      }
      return null;
    },
    [t],
  );

  const validateForm = useCallback(() => {
    const newErrors: Record<string, string> = {};

    let isValid = true;
    for (const field of memberFields) {
      // Skip validation for non-editable fields
      if (!field.isEditable || field.key === MemberFieldKey.BuilderAccess) {
        continue;
      }

      const fieldValue =
        field.key in formState
          ? formState[field.key as keyof UserProfile]
          : formState.clientCustomData?.[currentClient!.id]?.[field.key as keyof UserClientSpecificCustomData];

      const error = validateField(field.key, fieldValue, field.isRequired);

      if (error) {
        newErrors[field.key] = error;
        isValid = false;
      }
    }

    setErrors(newErrors);
    setIsFormValid(isValid);
  }, [currentClient, formState, memberFields, validateField]);

  useEffect(() => {
    validateForm();
  }, [formState, memberFields, validateForm]);

  const saveProfile = useCallback(() => {
    if (!isFormValid) return;

    withBusy(
      'save-profile',
      CurrentUserService.updateProfile(formState)
        .then((res) => {
          const updatedFields = Object.keys(res.data).reduce((acc, key) => {
            // Update only current user with fields filled in the form otherwise it will be overwritten, like roles etc..
            // Check if the key exists in memberFields and matches a key in the User type
            if ([...memberFields.map((field) => field.key), 'userImageId'].some((x) => x === key) && key in res.data) {
              const value = res.data[key as keyof User];
              // Ensure the value aligns with the User type
              (acc as Record<string, unknown>)[key] = value;
            }
            return acc;
          }, {} as User);

          updatedFields.language = formState.languageCode as SupportedLanguage;

          StorageService.setLang(res.data.language);
          setCurrentUser({ ...currentUser, ...updatedFields, clientCustomData: { ...(res.data.clientCustomData ?? {}) } });
          res.data.language && i18next.changeLanguage(res.data.language);
          toasts.addToast({
            title: t('user-settings:details.toasts.user-profile-saved.title'),
            description: t('user-settings:details.toasts.user-profile-saved.description'),
            type: ToastType.SUCCESS,
            expiresInMs: 5000,
          });
        })
        .catch((err) => {
          console.error(err);
          toasts.addToast({
            title: t('user-settings:details.toasts.user-profile-saved-failed.title'),
            description: t('user-settings:details.toasts.user-profile-saved-failed.description'),
            type: ToastType.ERROR,
          });
        }),
    );
  }, [isFormValid, withBusy, formState, setCurrentUser, currentUser, toasts, t, memberFields]);

  const resetPassword = () => {
    if (currentUser?.email) {
      withBusy(
        'reset-password',
        AuthService.forgotPassword(currentUser?.email).then((res) => {
          if (res.data) {
            toasts.addToast({
              title: t('user-settings:details.toasts.reset-email.title'),
              description: t('user-settings:details.toasts.reset-email.description'),
              type: ToastType.INFO,
              expiresInMs: 5000,
            });
          } else {
            toasts.addToast({
              title: t('user-settings:details.toasts.reset-email-failed.title'),
              description: t('user-settings:details.toasts.reset-email-failed.description'),
              type: ToastType.ERROR,
              expiresInMs: 5000,
            });
          }
        }),
      );
    }
  };

  const ssoEnabled = import.meta.env.VITE_SSO_ENABLED === 'true';

  const currentUserHighestRole = useMemo(() => {
    const accountId = currentClient?.id || currentUser?.defaultAccountId;
    if (!accountId) return null;
    return getHighestRole(currentUser?.roles[currentClient?.id || currentUser?.defaultAccountId || ''] || []);
  }, [currentClient?.id, currentUser?.defaultAccountId, currentUser?.roles]);

  return (
    <div className="mb-6 flex h-full flex-col">
      <div className="border-gray-2 sticky top-0 z-10 flex justify-between gap-3 border-b bg-white px-6 py-4">
        <div className="flex flex-grow items-center gap-4">
          {currentUser && (
            <ProfileAvatar
              size={ImageSize.L}
              user={{ ...currentUser, userImageId: formState.userImageId }}
              allowEdit
              onImageChanged={(id: string) => {
                handleFieldChange('userImageId', id, false, null);
                queryClient.invalidateQueries({ queryKey: ['clientUsers', currentClient?.id], exact: false });
                queryClient.invalidateQueries({ queryKey: ['formUsers'], exact: false });
              }}
            />
          )}
          <div>
            <div className="font-medium">
              {formState?.firstName || ''} {formState?.lastName || ''}
            </div>
            {currentUserHighestRole && t(`common:roles.${currentUserHighestRole}`)}
          </div>
        </div>
        <div className="flex flex-shrink-0 items-center gap-2">
          {!ssoEnabled && (
            <div className="flex items-start gap-4">
              {currentUser?.loggedInMethod === LoginMethods.Password && (
                <Button
                  data-cy="reset-password"
                  title={t('user-settings:details.buttons.password-reset')}
                  type={ButtonType.PRIMARY}
                  onClick={resetPassword}
                  size={ButtonSize.S}
                  loading={isBusy('reset-password')}
                >
                  {t('user-settings:details.buttons.password-reset')}
                </Button>
              )}
              <Button
                data-cy="save-user"
                title={t('user-settings:details.buttons.save')}
                type={ButtonType.PRIMARY}
                onClick={saveProfile}
                disabled={!isFormValid || !hasChanges}
                size={ButtonSize.S}
                loading={isBusy('save-profile')}
              >
                {t('user-settings:details.buttons.save')}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="border-gray-5 flex max-w-[650px] flex-col border-b-2 px-2 pb-8 pt-2">
        {memberFields.map((field) => {
          const renderer = FieldRenderer[field.key as MemberFieldKey];
          if (!renderer) return null;

          return (
            <div key={field.key} className="px-4">
              {renderer({
                field: { ...field, disabled: ssoEnabled || field.key === MemberFieldKey.Email },
                userProfile: formState,
                errors,
                onFieldChange: handleFieldChange,
                t,
                clientId: currentClient?.id ?? null,
              })}
            </div>
          );
        })}
        <div className="px-4">
          <DropdownSelect
            data-cy="pref-language"
            label={t('user-settings:details.prefered-language')}
            onChange={(data) => handleFieldChange('languageCode', data.value, true, null)}
            options={languageOptions}
            value={languageOptions.find((x) => x.value === formState.languageCode)}
            disabled={ssoEnabled}
          />
        </div>
      </div>
    </div>
  );
};

export default Profile;
